import React, { useEffect, useState } from "react";
import "./App.css";
import { Document, Page, pdfjs } from "react-pdf";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { AiOutlineFilePdf } from "react-icons/ai";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function MyPdfViewer({ isDesktop }) {
  const pdfURL = "./resume.pdf";
  const [scale, setScale] = useState(1.5);
  useEffect(() => {
    const handleResize = async () => {
      // Example: Adjust scale based on the container width
      const containerWidth = await document.getElementById("pdfViewerContainer")
        .offsetWidth;
      const desiredWidth = 800; // Desired width of the PDF content in pixels
      await setScale(containerWidth / desiredWidth);
    };

    // window.addEventListener("resize", handleResize);
    handleResize(); // Initial scale adjustment

    // return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ overflow: "auto", top: 0 }}>
      <Document file={pdfURL} options={{ workerSrc: "/pdf.worker.min.js" }}>
        <Page
          pageNumber={1}
          renderTextLayer={false}
          scale={isDesktop ? scale : 0.8}
        />
      </Document>
      {/* Add more <Page> components or implement pagination to navigate through the document */}
    </div>
  );
}

// Basic styles for the buttons. You might want to enhance these with your own CSS or a framework.
const buttonStyle = {
  margin: "10px",
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  width: "10rem",
  fontSize: "1.3rem",
};

// Icons can be imported from a library like react-icons, material-ui icons, etc.
// For simplicity, we'll just use text labels here.
function SocialButton({ url, label, onClick, isDesktop }) {
  let Icon;
  switch (label) {
    case "GitHub":
      Icon = FaGithub;
      break;
    case "LinkedIn":
      Icon = FaLinkedin;
      break;
    case "Resume":
      Icon = AiOutlineFilePdf;
      break;
    default:
      Icon = null; // Default case, no icon
  }
  // const fontSize = isDesktop ? "1.3rem" : "0.8rem";
  // const marginRight = isDesktop ? "8px" : "0px";
  // const buttonStyle1 = isDesktop
  //   ? buttonStyle
  //   : { ...buttonStyle, fontSize: "1rem", padding: "5px 10px", margin: "px" };

  return (
    <button style={buttonStyle} onClick={() => onClick(url)}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {Icon && <Icon style={{ marginRight: "8px", fontSize: "1.3rem" }} />}
        {label}
      </div>
    </button>
  );
}

function handleClick(url) {
  if (!url) return;

  // Check if the URL is a link to a file for download; you might use a different approach based on your file hosting.
  if (url.endsWith(".pdf")) {
    window.open(url, "_blank");
  } else {
    window.open(url, "_blank"); // Open in a new tab
  }
}

const desktopSocialStyle = {
  display: "flex",
  flexDirection: "column",
  minWidth: "200px",
  maxWidth: "400px",
  marginLeft: "4rem",
  marginTop: "3rem",
  top: 0,
  position: "sticky",
};

const mobileSocialStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  // minWidth: "200px",
  // marginLeft: "4rem",
  width: "100vw",
  marginBottom: "1.2rem",
  top: 0,
  position: "sticky",
};

function SocialMediaLinks({ isDesktop }) {
  return (
    <div style={isDesktop ? desktopSocialStyle : mobileSocialStyle}>
      <SocialButton
        label="GitHub"
        url="https://github.com/hamin-lee"
        onClick={handleClick}
        isDesktop={isDesktop}
      />
      <SocialButton
        label="LinkedIn"
        url="https://linkedin.com/in/hamin-lee"
        onClick={handleClick}
        isDesktop={isDesktop}
      />
      {isDesktop && (
        <SocialButton
          label="Resume"
          url="https://www.hamin.dev"
          onClick={handleClick}
          isDesktop={isDesktop}
        />
      )}
    </div>
  );
}

const MobileComponent = () => (
  <div
    id="pdfViewerContainer"
    style={{
      display: "flex",
      flexDirection: "column",
      // alignItems: "center",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <div style={{ top: 0, position: "sticky" }}>
      <SocialMediaLinks isDesktop={false} />
    </div>
    <MyPdfViewer isDesktop={false} />
  </div>
);
const DesktopComponent = () => (
  <div
    id="pdfViewerContainer"
    style={{
      display: "flex",
      flexDirection: "row",
      // alignItems: "center",
      justifyContent: "center",
    }}
  >
    <MyPdfViewer isDesktop={true} />
    <div style={{ top: 0, position: "sticky" }}>
      <SocialMediaLinks isDesktop={true} />
    </div>
  </div>
);

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width to state
      setWindowWidth(window.innerWidth);
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount
  // Define the width below which the app is considered to be in 'mobile' view
  const mobileWidthThreshold = 768;

  return (
    <div id="pdfViewerContainer2">
      {windowWidth <= mobileWidthThreshold ? (
        <MobileComponent />
      ) : (
        <DesktopComponent />
      )}
    </div>
  );
  // return (
  //   <div
  //     id="pdfViewerContainer"
  //     style={{
  //       display: "flex",
  //       flexDirection: "row",
  //       // alignItems: "center",
  //       justifyContent: "center",
  //     }}
  //   >
  //     <MyPdfViewer />
  //     <div style={{ top: 0, position: "sticky" }}>
  //       <SocialMediaLinks />
  //     </div>
  //   </div>
  // );
}

export default App;
